import { Injectable } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Injectable({
  providedIn: 'root'
})
export class WrapperSpinnerService {

  private count:number = 0;

  constructor(private spinnerService: Ng4LoadingSpinnerService) { }

  show(): void {
    if (!this.count) {
      this.spinnerService.show();
    }

    this.count++;
  }

  hide(): void {
    this.count = Math.max(0, this.count - 1);

    if (!this.count) {
      this.spinnerService.hide();
    }
  }

}
