import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  public setObject(key, object) {
    localStorage.setItem(key, JSON.stringify(object));
  }

  public getObject(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  public delete(key) {
    return localStorage.removeItem(key);
  }

  public clear() {
    return localStorage.clear();
  }

  public setValue(key, value) {
    localStorage.setItem(key, value);
  }

  public getValue(key) {
    return localStorage.getItem(key);
  }
}
